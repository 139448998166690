body {
  /* background-image: url('./ok.gif'); */
  
  background-repeat: no-repeat;
  background-size: 100vw 100vh; 
}

.title {
  font-size: 40px; 
}

.centered {
  padding: 10px;
  font-size: 20px;
  text-align: center;
}

.init-icos {
  margin: 10px;
  /* display: grid; */
}

.ico {
  display: inline-grid;
  color: white;
  padding: 06px !important;
  text-align: center;
}

.my-computer-text {
  margin-left: -8px;
}

a {
  text-decoration: none;
  /* padding: 10px; */
}

.contact {
  display: grid;
}

.contact > a {
  display: grid !important;
  justify-items: center;
}

.contact-container {
  display: flex;
  justify-content: space-around;
}

.links {
  margin: 10px;
}

.links > a {
  margin: 10px;
  padding: 10px;
}

.winamp-container {
  
  left: -300px;
  top: 50%;
  
  z-index: 1000; /* Ensure it is above other elements */ 
}



